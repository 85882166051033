import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import areas from '../areas';
import 'jspdf-autotable';
import baseURL from '../url';
import renovaciones from '../renovaciones';
import zonales from '../zonales';
import estaciones from '../estaciones';
export default function Productos() {
    const [productos, setProductos] = useState([]);
    const [filtroId1, setFiltroId1] = useState('');
    const [filtroTitulo, setFiltroTitulo] = useState('');
    const [filtroCeco, setFiltroCeco] = useState('');
    const [filtroZonal, setFiltroZonal] = useState('');
    const [filtroEstacion, setFiltroEstacion] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    useEffect(() => {
        cargarProductos();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
                console.log(data.productos);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };
    const Filtrados = productos.filter(item => {
        const idUsuarioMatch1 = item?.idProducto?.toString().includes(filtroId1);
        const tituloMatch = item?.titulo?.toLowerCase().includes(filtroTitulo?.toLowerCase());
        const cecoMatch = !filtroCeco || item.area.includes(filtroCeco);
        const zonalMatch = !filtroZonal || item.zonal.includes(filtroZonal);
        const estacionMatch = !filtroEstacion || item.estacion.includes(filtroEstacion);
        return idUsuarioMatch1 && tituloMatch && cecoMatch && estacionMatch && zonalMatch;
    });
    const calcularDiasRestantes = (createdAt, renovacionMeses) => {
        const fechaCreacion = new Date(createdAt);
        const fechaRenovacion = new Date(fechaCreacion.setMonth(fechaCreacion.getMonth() + renovacionMeses));
        const hoy = new Date();
        const diferencia = fechaRenovacion - hoy;
        const diasRestantes = Math.ceil(diferencia / (1000 * 60 * 60 * 24));

        return diasRestantes;
    };

    const obtenerColorDeFondo = (diasRestantes) => {
        if (diasRestantes <= 0) {
            return { color: '#FF6347', texto: 'Vencido' }; // Rojo y texto "Vencido" si ya se cumplió la renovación
        } else if (diasRestantes <= 30) {
            return { color: '#DAA520', texto: `${diasRestantes} días restantes` }; // Amarillo si está a menos de un mes
        } else {
            return { color: '#008000', texto: `${diasRestantes} días restantes` }; // Verde si tiene más de un mes
        }
    };

    const descargarExcel = () => {
        const dataToExport = Filtrados.map(item => {
            const diasRestantes = calcularDiasRestantes(item.createdAt, item.renovacion);
            const { texto: textoRenovacion } = obtenerColorDeFondo(diasRestantes);

            // Buscar el objeto de renovación correspondiente y obtener el texto de renovación
            const renovacionTexto = renovaciones
                .filter(filtro => filtro.value === item.renovacion)
                .map(filtro2 => filtro2.renovacion)
                .join(', ') || 'No especificado';

            return {

                'Zonal': item.zonal,
                'ATTO': item.estacion,
                'CECO': item.area,
                'Colaborador': item.nombre,
                'Historico': item.historico,
                'Codigo': item.codigo,
                'EPPS': item.titulo,
                'Cantidad': item.cantidad,
                'Fecha': item.createdAt,
                'Renovacion': renovacionTexto,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'productos');
        XLSX.writeFile(workbook, 'productos.xlsx');
    };

    const descargarPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(10);
        doc.text('Datos de productos', 14, 16);

        const tableColumn = ["Zonal", "ATTO", "CECO", "Colaborador", "Historico", "Codigo", "EPPS", "Cantidad", "Fecha de entrega", "Renovacion"];

        const tableRows = Filtrados.map(item => {
            const diasRestantes = calcularDiasRestantes(item.createdAt, item.renovacion);
            const { texto: textoRenovacion } = obtenerColorDeFondo(diasRestantes);

            // Buscar el objeto de renovación correspondiente y obtener el texto de renovación
            const renovacionTexto = renovaciones
                .filter(filtro => filtro.value === item.renovacion)
                .map(filtro2 => `${filtro2.renovacion}`)
                .join(', ') || 'No especificado';

            return [

                item.zonal,
                item.estacion,
                item.area,
                item.nombre,
                item.historico,
                item.codigo,
                item.titulo,
                item.cantidad,
                item.createdAt,
                renovacionTexto,
            ];
        });

        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.save('productos.pdf');
    };
    const recargar = () => {
        cargarProductos();
    };

    const invertirOrden = () => {
        setProductos([...productos].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    return (
        <div className='table-containerProductos'>
            <div className='deFlexContent'>
                <div className='deFlex2'>

                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>

                <div className='filtrosContain'>

                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroTitulo}
                            onChange={(e) => setFiltroTitulo(e.target.value)}
                            placeholder='EPPS'
                        />
                    </div>
                    <div className='inputsColumn'>
                        <select
                            value={filtroCeco}
                            onChange={(e) => setFiltroCeco(e.target.value)}
                        >
                            <option value="">CECO</option>
                            {
                                areas?.map(item => (
                                    <option value={item?.value}>{item?.area}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='inputsColumn'>
                        <select
                            value={filtroZonal}
                            onChange={(e) => setFiltroZonal(e.target.value)}
                        >
                            <option value="">Zonal</option>
                            {
                                zonales?.map(item => (
                                    <option value={item?.value}>{item?.zonal}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='inputsColumn'>
                        <select
                            value={filtroEstacion}
                            onChange={(e) => setFiltroEstacion(e.target.value)}
                        >
                            <option value="">ATTO</option>
                            {
                                estaciones?.map(item => (
                                    <option value={item?.value}>{item?.estacion}</option>
                                ))
                            }
                        </select>
                    </div>
                    <button className='reload'>
                        {Filtrados?.length}
                    </button>
                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>
                </div>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>ATTO</th>
                        <th>CECO</th>
                        <th>Colaborador</th>
                        <th>Historico</th>
                        <th>Codigo</th>
                        <th>EPPS</th>
                        <th>Cantidad</th>
                        <th>Fecha de entrega</th>
                        <th>Renovacion</th>
                    </tr>
                </thead>
                <tbody>
                    {Filtrados.map(item => {
                        const diasRestantes = calcularDiasRestantes(item.createdAt, item.renovacion);
                        const { color: colorFondo, texto: textoRenovacion } = obtenerColorDeFondo(diasRestantes);

                        return (
                            <tr key={item.idProducto}>

                                <td>{item.estacion}</td>
                                <td>{item.area}</td>
                                <td>{item.nombre}</td>
                                <td style={{ color: item.historico === 'ACTIVO' ? '#008000' : item.historico === 'CESADO' ? '#FF6347' : '#000000' }}>
                                    {item.historico}
                                </td>
                                <td>{item.codigo}</td>
                                <td>{item.titulo}</td>
                                <td>{item.cantidad}</td>
                                <td>{new Date(item?.createdAt).toLocaleDateString()}</td>
                                {
                                    renovaciones.filter(filtro => filtro.value === item.renovacion).map(filtro2 => (
                                        <td style={{ color: colorFondo }}>
                                            {filtro2.renovacion} ({textoRenovacion})
                                        </td>
                                    ))
                                }

                            </tr>
                        );
                    })}

                </tbody>
            </table>

        </div>
    );
}
