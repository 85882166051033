import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './ProductosMain.css';
import baseURL from '../../url';
import renovaciones from '../../renovaciones';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function ProductosMain() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        cargarProductos();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos.reverse().slice(0, 5) || []);
                console.log(data.productos);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const calcularDiasRestantes = (createdAt, renovacionMeses) => {
        const fechaCreacion = new Date(createdAt);
        const fechaRenovacion = new Date(fechaCreacion.setMonth(fechaCreacion.getMonth() + renovacionMeses));
        const hoy = new Date();
        const diferencia = fechaRenovacion - hoy;
        const diasRestantes = Math.ceil(diferencia / (1000 * 60 * 60 * 24));

        return diasRestantes;
    };

    const obtenerColorDeFondo = (diasRestantes) => {
        if (diasRestantes <= 0) {
            return { color: '#FF6347', texto: 'Vencido' }; // Rojo y texto "Vencido" si ya se cumplió la renovación
        } else if (diasRestantes <= 30) {
            return { color: '#DAA520', texto: `${diasRestantes} días restantes` }; // Amarillo si está a menos de un mes
        } else {
            return { color: '#008000', texto: `${diasRestantes} días restantes` }; // Verde si tiene más de un mes
        }
    };




    return (
        <div className='table-containerProductos'>
            <div className='deFlexMore'>
                <h3>Últimos {productos.length} productos</h3>
                <Anchor to={`/dashboard/productos`} className='logo'>
                    Ver más
                </Anchor>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Zonal</th>
                        <th>ATTO</th>
                        <th>CECO</th>
                        <th>Colaborador</th>
                        <th>Historico</th>
                        <th>Codigo</th>
                        <th>EPPS</th>
                        <th>Cantidad</th>
                        <th>Fecha de entrega</th>
                        <th>Renovacion</th>

                    </tr>
                </thead>
                <tbody>
                    {productos.map(item => {
                        const diasRestantes = calcularDiasRestantes(item.createdAt, item.renovacion);
                        const { color: colorFondo, texto: textoRenovacion } = obtenerColorDeFondo(diasRestantes);

                        return (
                            <tr key={item.idProducto}>
                                <td>{item.idProducto}</td>
                                <td>{item.zonal}</td>
                                <td>{item.estacion}</td>
                                <td>{item.area}</td>
                                <td>{item.nombre}</td>
                                <td style={{ color: item.historico === 'ACTIVO' ? '#008000' : item.historico === 'CESADO' ? '#FF6347' : '#000000' }}>
                                    {item.historico}
                                </td>
                                <td>{item.codigo}</td>
                                <td>{item.titulo}</td>
                                <td>{item.cantidad}</td>
                                <td>{new Date(item?.createdAt).toLocaleDateString()}</td>
                                {
                                    renovaciones.filter(filtro => filtro.value === item.renovacion).map(filtro2 => (
                                        <td style={{ color: colorFondo }}>
                                            {filtro2.renovacion} ({textoRenovacion})
                                        </td>
                                    ))
                                }

                            </tr>
                        );
                    })}
                </tbody>
            </table>

        </div>
    );
}
