import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import areas from '../../areas';
import renovaciones from '../../renovaciones';
import zonales from '../../zonales';
import estaciones from '../../estaciones';
import historicos from '../../historicos';
export default function NewProducto() {
    const [mensaje, setMensaje] = useState('');
    const [titulo, setTitulo] = useState('');
    const [renovacion, setRenovacion] = useState('');
    const [area, setArea] = useState('');
    const [nombre, setNombre] = useState('');
    const [zonal, setZonal] = useState('');
    const [estacion, setEstacion] = useState('');
    const [historico, setHistorico] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [codigo, setCodigo] = useState(0);
    const crear = async () => {
        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('renovacion', renovacion);
        formData.append('area', area);
        formData.append('nombre', nombre);
        formData.append('zonal', zonal);
        formData.append('estacion', estacion);
        formData.append('historico', historico);
        formData.append('cantidad', cantidad);
        formData.append('codigo', codigo);
        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/productoPost.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                toggleModal();
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };
    const handleArea = (e) => {
        setArea(e.target.value);
    };
    const handleRenovacion = (e) => {
        setRenovacion(e.target.value);
    };
    const handleZonales = (e) => {
        setZonal(e.target.value);
    };
    const handleEstaciones = (e) => {
        setEstacion(e.target.value);
    };
    const handlehistorico = (e) => {
        setHistorico(e.target.value);
    };
    const toggleModal = () => {
        setTitulo('');
        setRenovacion('');
        setArea('');
        setNombre('');
        setZonal('');
        setEstacion('');
        setHistorico('');
        setCantidad(0)
        setModalOpen(!modalOpen);
    };

    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span>+</span> Agregar
            </button>
            {modalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>
                                Agregar producto
                            </button>
                            <span className='close' onClick={toggleModal}>
                                &times;
                            </span>
                        </div>
                        <form id="crearForm">
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>EPPS</legend>
                                    <input
                                        type='text'
                                        name='titulo'
                                        placeholder='EPPS'
                                        value={titulo}
                                        onChange={(e) => setTitulo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Codigo</legend>
                                    <input
                                        type='number'
                                        name='codigo'
                                        placeholder='Codigo'
                                        value={codigo}
                                        onChange={(e) => setCodigo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Cantidad</legend>
                                    <input
                                        type='number'
                                        name='cantidad'
                                        placeholder='Cantidad'
                                        value={cantidad}
                                        onChange={(e) => setCantidad(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Colaborador</legend>
                                    <input
                                        type='text'
                                        name='nombre'
                                        placeholder='Colaborador'
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Fecha de entrega</legend>
                                    <select
                                        id="renovacion"
                                        name='renovacion'
                                        value={renovacion}
                                        onChange={handleRenovacion}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            renovaciones?.map(item => (
                                                <option value={item?.value}>{item?.renovacion}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>CECO </legend>
                                    <select
                                        id="area"
                                        name="area"
                                        value={area}
                                        onChange={handleArea}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            areas?.map(item => (
                                                <option value={item?.value}>{item?.area}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>


                                <fieldset>
                                    <legend>Zonal </legend>
                                    <select
                                        id="zonal"
                                        name="zonal"
                                        value={zonal}
                                        onChange={handleZonales}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            zonales?.map(item => (
                                                <option value={item?.value}>{item?.zonal}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>ATTO</legend>
                                    <select
                                        id="estacion"
                                        name="estacion"
                                        value={estacion}
                                        onChange={handleEstaciones}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            estaciones?.map(item => (
                                                <option value={item?.value}>{item?.estacion}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Historico</legend>
                                    <select
                                        id="historico"
                                        name="historico"
                                        value={historico}
                                        onChange={handlehistorico}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            historicos?.map(item => (
                                                <option value={item?.value}>{item?.historico}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>

                            </div>
                            {mensaje ? (
                                <button type='button' className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type='button' onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
