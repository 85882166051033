import React from 'react'
import Productos from '../../Components/Productos/Productos'
import './ProductosPage.css'
import logo from '../../images/logo3.png'
export default function ProductosPage() {
    return (
        <div className='ProductosPage'>
            <img src={logo} alt="" />
            <Productos />

        </div>
    )
}
