import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import baseURL from '../../url';
import areas from '../../areas';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import NewProducto from '../NewProducto/NewProducto';
import renovaciones from '../../renovaciones';
import zonales from '../../zonales';
import estaciones from '../../estaciones';
import historicos from '../../historicos';
export default function ProductosData() {
    const [productos, setProductos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [item, setItem] = useState({});
    const [idProducto, setidProducto] = useState('');
    const [filtroId1, setFiltroId1] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [nombre, setNombre] = useState('');
    const [zonal, setZonal] = useState('');
    const [estacion, setEstacion] = useState('');
    const [historico, setHistorico] = useState('');
    const [createdAt, setcreatedAt] = useState('');
    const [renovacion, setRenovacion] = useState('');
    const [area, setArea] = useState('');
    const [filtroTitulo, setFiltroTitulo] = useState('');
    const [filtroCeco, setFiltroCeco] = useState('');
    const [filtroZonal, setFiltroZonal] = useState('');
    const [filtroEstacion, setFiltroEstacion] = useState('');
    const [cantidad, setCantidad] = useState(0);
    const [codigo, setCodigo] = useState(0);
    useEffect(() => {
        cargarProductos();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
                console.log(data.productos);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const eliminar = (idProducto) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/productoDelete.php?idProducto=${idProducto}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarProductos();
                    })
                    .catch(error => {
                        console.error('Error al eliminar :', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setItem(item);
        setArea(item.area);
        setRenovacion(item.renovacion);
        setTitulo(item.titulo);
        setNombre(item.nombre);
        setZonal(item.zonal);
        setEstacion(item.estacion);
        setHistorico(item.historico);
        setcreatedAt(item.createdAt);
        setCantidad(item.cantidad);
        setCodigo(item.codigo);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleUpdate = (idProducto) => {
        const payload = {
            titulo: titulo !== '' ? titulo : item.titulo,
            renovacion: renovacion !== '' ? renovacion : item.renovacion,
            area: area !== '' ? area : item.area,
            nombre: nombre !== '' ? nombre : item.nombre,
            zonal: zonal !== '' ? zonal : item.zonal,
            estacion: estacion !== '' ? estacion : item.estacion,
            historico: historico !== '' ? historico : item.historico,
            createdAt: createdAt !== '' ? createdAt : item.createdAt,
            cantidad: cantidad !== '' ? cantidad : item.cantidad,
            codigo: codigo !== '' ? codigo : item.codigo,
        };

        fetch(`${baseURL}/productoPut.php?idProducto=${idProducto}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire('Error!', data.error, 'error');
                } else {
                    Swal.fire('Editado!', data.mensaje, 'success');
                    cargarProductos();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.error(error.message);
                toast.error(error.message);
            });
    };

    const calcularDiasRestantes = (createdAt, renovacionMeses) => {
        const fechaCreacion = new Date(createdAt);
        const fechaRenovacion = new Date(fechaCreacion.setMonth(fechaCreacion.getMonth() + renovacionMeses));
        const hoy = new Date();
        const diferencia = fechaRenovacion - hoy;
        const diasRestantes = Math.ceil(diferencia / (1000 * 60 * 60 * 24));

        return diasRestantes;
    };

    const obtenerColorDeFondo = (diasRestantes) => {
        if (diasRestantes <= 0) {
            return { color: '#FF6347', texto: 'Vencido' }; // Rojo y texto "Vencido" si ya se cumplió la renovación
        } else if (diasRestantes <= 30) {
            return { color: '#DAA520', texto: `${diasRestantes} días restantes` }; // Amarillo si está a menos de un mes
        } else {
            return { color: '#008000', texto: `${diasRestantes} días restantes` }; // Verde si tiene más de un mes
        }
    };
    const Filtrados = productos.filter(item => {
        const idUsuarioMatch1 = item?.idProducto?.toString().includes(filtroId1);
        const tituloMatch = item?.titulo?.toLowerCase().includes(filtroTitulo?.toLowerCase());
        const cecoMatch = !filtroCeco || item.area.includes(filtroCeco);
        const zonalMatch = !filtroZonal || item.zonal.includes(filtroZonal);
        const estacionMatch = !filtroEstacion || item.estacion.includes(filtroEstacion);
        return idUsuarioMatch1 && tituloMatch && cecoMatch && estacionMatch && zonalMatch;
    });

    const recargar = () => {
        cargarProductos();
    };

    const invertirOrden = () => {
        setProductos([...productos].reverse());
        setOrdenInvertido(!ordenInvertido);
    };

    const descargarExcel = () => {
        const dataToExport = Filtrados.map(item => {
            // Buscar el objeto de renovación correspondiente y obtener el texto de renovación
            const renovacionTexto = renovaciones
                .filter(filtro => filtro.value === item.renovacion)
                .map(filtro2 => filtro2.renovacion)
                .join(', ') || 'No especificado';

            return {
                'ID': item.idProducto,
                'Zonal': item.zonal,
                'ATTO': item.estacion,
                'CECO': item.area,
                'Colaborador': item.nombre,
                'Historico': item.historico,
                'Codigo': item.codigo,
                'EPPS': item.titulo,
                'Cantidad': item.cantidad,
                'Fecha': item.createdAt,
                'Renovacion': renovacionTexto,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'productos');
        XLSX.writeFile(workbook, 'productos.xlsx');
    };


    const descargarPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(10);
        doc.text('Datos de productos Filtrados', 14, 16);

        const tableColumn = ["ID", "Zonal", "ATTO", "CECO", "Colaborador", "Historico", "Codigo", "EPPS", "Cantidad", "Fecha de entrega", "Renovacion"];

        const tableRows = Filtrados.map(item => {
            // Buscar el objeto de renovación correspondiente y obtener el texto de renovación
            const renovacionTexto = renovaciones
                .filter(filtro => filtro.value === item.renovacion)
                .map(filtro2 => `${filtro2.renovacion}`)
                .join(', ') || 'No especificado';

            return [
                item.idProducto,
                item.zonal,
                item.estacion,
                item.area,
                item.nombre,
                item.historico,
                item.codigo,
                item.titulo,
                item.cantidad,
                item.createdAt,
                renovacionTexto,
            ];
        });

        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.save('productos.pdf');
    };



    return (
        <div>
            <ToastContainer />
            <div className='deFlexContent'>
                <div className='deFlex2'>
                    <NewProducto />
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>

                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <input type="number" value={filtroId1} onChange={(e) => setFiltroId1(e.target.value)} placeholder='ID ' />
                    </div>
                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroTitulo}
                            onChange={(e) => setFiltroTitulo(e.target.value)}
                            placeholder='EPPS'
                        />
                    </div>
                    <div className='inputsColumn'>
                        <select
                            value={filtroCeco}
                            onChange={(e) => setFiltroCeco(e.target.value)}
                        >
                            <option value="">CECO</option>
                            {
                                areas?.map(item => (
                                    <option value={item?.value}>{item?.area}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='inputsColumn'>
                        <select
                            value={filtroZonal}
                            onChange={(e) => setFiltroZonal(e.target.value)}
                        >
                            <option value="">Zonal</option>
                            {
                                zonales?.map(item => (
                                    <option value={item?.value}>{item?.zonal}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='inputsColumn'>
                        <select
                            value={filtroEstacion}
                            onChange={(e) => setFiltroEstacion(e.target.value)}
                        >
                            <option value="">ATTO</option>
                            {
                                estaciones?.map(item => (
                                    <option value={item?.value}>{item?.estacion}</option>
                                ))
                            }
                        </select>
                    </div>
                    <button className='reload'>
                        {Filtrados?.length}
                    </button>
                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>
                </div>
            </div>

            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Zonal</th>
                            <th>ATTO</th>
                            <th>CECO</th>
                            <th>Colaborador</th>
                            <th>Historico</th>
                            <th>Codigo</th>
                            <th>EPPS</th>
                            <th>Cantidad</th>
                            <th>Fecha de entrega</th>
                            <th>Renovacion</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Filtrados.map(item => {
                            const diasRestantes = calcularDiasRestantes(item.createdAt, item.renovacion);
                            const { color: colorFondo, texto: textoRenovacion } = obtenerColorDeFondo(diasRestantes);
                            return (
                                <tr key={item.idProducto}>
                                    <td>{item.idProducto}</td>
                                    <td>{item.zonal}</td>
                                    <td>{item.estacion}</td>
                                    <td>{item.area}</td>
                                    <td>{item.nombre}</td>
                                    <td style={{ color: item.historico === 'ACTIVO' ? '#008000' : item.historico === 'CESADO' ? '#FF6347' : '#000000' }}>
                                        {item.historico}
                                    </td>
                                    <td>{item.codigo}</td>
                                    <td>{item.titulo}</td>
                                    <td>{item.cantidad}</td>
                                    <td> <td>{new Date(item?.createdAt).toLocaleDateString()}</td></td>
                                    {
                                        renovaciones.filter(filtro => filtro.value === item.renovacion).map(filtro2 => (
                                            <td style={{ color: colorFondo }}>
                                                {filtro2.renovacion} ({textoRenovacion})
                                            </td>
                                        ))
                                    }


                                    <td>
                                        <button className='eliminar' onClick={() => eliminar(item.idProducto)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                        <button className='editar' onClick={() => abrirModal(item)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>
                                Editar
                            </button>
                            <button onClick={cerrarModal} className='close'>
                                &times;
                            </button>
                        </div>
                        <div className='sectiontext'>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>ID </legend>
                                    <input
                                        type="number"
                                        disabled
                                        value={idProducto !== '' ? idProducto : item.idProducto}
                                        onChange={(e) => setidProducto(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Colaborador</legend>
                                    <input
                                        type="text"
                                        value={nombre !== '' ? nombre : item.nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Fecha de entrega</legend>
                                    <input
                                        type="date"
                                        value={createdAt !== '' ? createdAt : item.createdAt}
                                        onChange={(e) => setcreatedAt(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Zonal</legend>
                                    <select
                                        id="zonal"
                                        name="zonal"
                                        value={zonal}
                                        onChange={(e) => setZonal(e.target.value)}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            zonales?.map(item => (
                                                <option value={item?.value}>{item?.zonal}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>ATTO</legend>
                                    <select
                                        id="estacion"
                                        name="estacion"
                                        value={estacion}
                                        onChange={(e) => setEstacion(e.target.value)}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            estaciones?.map(item => (
                                                <option value={item?.value}>{item?.estacion}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>EPPS</legend>
                                    <input
                                        type="text"
                                        value={titulo !== '' ? titulo : item.titulo}
                                        onChange={(e) => setTitulo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Codigo</legend>
                                    <input
                                        type="number"
                                        value={codigo !== '' ? codigo : item.codigo}
                                        onChange={(e) => setCodigo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Cantidad</legend>
                                    <input
                                        type="number"
                                        value={cantidad !== '' ? cantidad : item.cantidad}
                                        onChange={(e) => setCantidad(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Fecha de entrega</legend>
                                    <select
                                        id="renovacion"
                                        name="renovacion"
                                        value={renovacion}
                                        onChange={(e) => setRenovacion(e.target.value)}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            renovaciones?.map(item => (
                                                <option value={item?.value}>{item?.renovacion}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>CECO</legend>
                                    <select
                                        id="area"
                                        name="area"
                                        value={area}
                                        onChange={(e) => setArea(e.target.value)}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            areas?.map(item => (
                                                <option value={item?.value}>{item?.area}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Historico</legend>
                                    <select
                                        id="historico"
                                        name="historico"
                                        value={historico}
                                        onChange={(e) => setHistorico(e.target.value)}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            historicos?.map(item => (
                                                <option value={item?.value}>{item?.historico}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>

                            </div>
                            <button className='btnPost' onClick={() => handleUpdate(item.idProducto)} >Guardar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
