const estaciones = [
    { estacion: 'JAU', value: 'JAU' },
    { estacion: 'AYP', value: 'AYP' },
    { estacion: 'PIO', value: 'PIO' },
    { estacion: 'TCQ', value: 'TCQ' },
    { estacion: 'JUL', value: 'JUL' },

];

export default estaciones;
