import IndexLayout from "../Layouts/IndexLayout";
import MainLayout from "../Layouts/MainLayout";
import { createBrowserRouter } from "react-router-dom";
import Usuarios from '../Pages/Usuarios/Usuarios'
import Main from "./Main/Main";
import ProductosPage from "./ProductosPage/ProductosPage";
import Productos from "./Productos/Productos";
export const router = createBrowserRouter([

    {
        path: "/",
        element: <IndexLayout />,
        children: [
            {
                path: `/`,
                element: <Main />,
            },

            {
                path: `/dashboard/usuarios`,
                element: <Usuarios />,
            },

            {
                path: `/dashboard/productos`,
                element: <Productos />,
            },


        ],
    },
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: `/epps`,
                element: <ProductosPage />,
            },



        ],
    },



]);
