const renovaciones = [
    { value: 1, renovacion: '1 mes' },
    { value: 2, renovacion: '2 meses' },
    { value: 3, renovacion: '3 meses' },
    { value: 4, renovacion: '4 meses' },
    { value: 5, renovacion: '5 meses' },
    { value: 6, renovacion: '6 meses' },
    { value: 7, renovacion: '7 meses' },
    { value: 8, renovacion: '8 meses' },
    { value: 9, renovacion: '9 meses' },
    { value: 10, renovacion: '10 meses' },
    { value: 11, renovacion: '11 meses' },
    { value: 12, renovacion: '12 meses' },
];

export default renovaciones;
