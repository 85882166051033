
import './App.css';
import { router, } from "./Pages/index";
import { RouterProvider } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

function App() {
  const getTheme = () => {
    return JSON.parse(localStorage.getItem("theme")) || false
  }
  const [theme, setTheme] = useState(getTheme())
  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme))
  }, [theme])

  return (

    <div className={theme ? "theme-dark" : ""}>

      <RouterProvider router={router} />


      <button className='iconDark' htmlFor="darkmode" onClick={() => setTheme(!theme)} >
        <FontAwesomeIcon icon={theme ? faSun : faMoon} className="darkmode-icon" />

      </button>

    </div>

  );
}

export default App;
